/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'archive': {
			init: function() {
				jQuery( document ).ready( function ( $ ) {
					jQuery(".content .entry-title, .content .page-header h1").lettering('words');
				} );
			}
		},
		'common': {
			init: function() {
				jQuery( document ).ready( function ( $ ) {

					$(window).scroll(function() {    
					    var scroll = $(window).scrollTop();

					    if (scroll >= 10) {
					        $("body").addClass("scrolling");
					    } else {
					        $("body").removeClass("scrolling");
					    }
					});

					$('.mega-menu-toggle').remove();

					jQuery(".content .entry-title, .content .page-header h1, .content .page-header h2").lettering('words');
					jQuery.timeago.settings.strings.prefixAgo = '';
					jQuery("time.timeago").timeago();

					$('#change_field_category').change( function ( e ) {
						e.preventDefault();
						location.href = $(this).val();
					} );
					jQuery('.open_video').magnificPopup({
						type: 'iframe',
						iframe: {
							markup: '<div class="mfp-iframe-scaler">'+
									'<div class="mfp-close"></div>'+
									'<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
								  '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button

							patterns: {
								youtube: {
									index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

									id: 'v=', // String that splits URL in a two parts, second part should be %id%
									src: '//www.youtube.com/embed/%id%?autoplay=1' // URL that will be set as a source for iframe.
								},
								vimeo: {
									index: 'vimeo.com/',
									id: '/',
									src: '//player.vimeo.com/video/%id%?autoplay=1'
								},
								gmaps: {
									index: '//maps.google.',
									src: '%id%&output=embed'
								}

							},
							srcAction: 'iframe_src', // Templating object key. First part defines CSS selector, second attribute. "iframe_src" means: find "iframe" and set attribute "src".
						}
					});

					/*
					jQuery('.flexslider .slides li').each(function() { // the containers for all your galleries
						jQuery(this).magnificPopup({
							delegate: 'a', // the selector for gallery item
							type: 'iframe',
							iframe: {
								markup: '<div class="mfp-iframe-scaler">'+
										'<div class="mfp-close"></div>'+
										'<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
										'</div>', 
								patterns: {
									youtube: {
										index: 'youtube.com/', 
										start: 'start=', 
										id: 'v=', 
										src: '//www.youtube.com/embed/%id%&autoplay=1' 
									}
								},
								srcAction: 'iframe_src', 
							}
						});
					});
					setTimeout( function () {
						jQuery('.flexslider .slides li').each(function() { // the containers for all your galleries
							jQuery(this).magnificPopup({
								delegate: 'a', // the selector for gallery item
								type: 'iframe',
								iframe: {
									markup: '<div class="mfp-iframe-scaler">'+
											'<div class="mfp-close"></div>'+
											'<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
											'</div>', 
									patterns: {
										youtube: {
											index: 'youtube.com/', 
											start: 'start=', 
											id: 'v=', 
											src: '//www.youtube.com/embed/%id%&autoplay=1' 
										}
									},
									srcAction: 'iframe_src', 
								}
							});
						});
					}, 1000 );
					*/
					if ( innerWidth>1200 ) {
						setTimeout( function () {
							if( typeof $crisp != 'undefined' && $crisp && $crisp.on ) {
								$crisp.on( "chat:opened", function () {
									$("#crisp-chatbox > div > div").css("cssText", "width: 350px !important;");
									$('#crisp-chatbox div.crisp-zyxrmn').css("cssText", "height: 350px !important;");
									setTimeout( function () {
										$('#crisp-chatbox > div > div').css("cssText", "width: 350px !important;");
										$('#crisp-chatbox div.crisp-zyxrmn').css("cssText", "height: 350px !important;");
									} );
								});
							}
						}, 1000 );
					};
				} );

				$('button.navbar-toggler').click( function (e) {
					e.preventDefault();
					$('header.banner').toggleClass('open_menu');
				} );

				window.$ = jQuery;

				/*
				if ( window.matchMedia("(max-width: 767px)").matches ) {
					$('[data-slick]').slick({
						//centerMode: true,
						//initialSlide: 1,
						prevArrow: '<a class="slick-prev">Previous</a>',
						nextArrow: '<a class="slick-next">Next</a>',
						infinite: false,
						centerPadding: '0px',
						slidesToShow: 1,
					});
				}
				*/

				if ( window.matchMedia("(max-width: 767px)").matches ) {
				}

				if ( innerWidth <= 767 ) {
					$('.widget_widget_videos_list .section_wrap > .row').slick({
						//centerMode: true,
						//initialSlide: 1,
						prevArrow: '<a class="slick-prev">Previous</a>',
						nextArrow: '<a class="slick-next">Next</a>',
						infinite: true,
						centerPadding: '0px',
						slidesToShow: 1,
					});
				}


				$('[data-slick]').slick({
					centerMode: true,
					initialSlide: 1,
					prevArrow: '<a class="slick-prev">Previous</a>',
					nextArrow: '<a class="slick-next">Next</a>',
					infinite: false,
					centerPadding: '300px',
					slidesToShow: 1,
					//variableWidth: true,
  					responsive: [
					    {
					    	breakpoint: 767,
					    	settings: {
								centerPadding: '0px',
					    	}
					    },
  					]
				});

				//$('.search-form').css("display", "flex").hide();
				$('.show_search').click( function (e) {
					e.preventDefault();
					$(this).parent().find( '.search-form').fadeToggle();
				} );
				$('ul.nav li.dropdown').hover(function() {
					$(this).find('>.dropdown-menu').stop(true, true).delay(200).fadeIn(500);
				}, function() {
					$(this).find('>.dropdown-menu').stop(true, true).delay(200).fadeOut(500);
				});
				
				$('#back-to-top').click(function () {
					$('body,html').animate({
						scrollTop: 0
					}, 800);
					return false;
				});
				$('.orderby').select2({
					width: "element" 
				});
				$('.woocommerce div.product form.cart .variations select').select2({
					width: "element" 
				});
				var sourceSwap = function () {
					var $this = $(this).find('img');
					var newSource = $this.data('alt-src');
					$this.data('alt-src', $this.attr('src'));
					$this.attr('src', newSource);
				};
				$('.login_reparto_carrito li').hover(sourceSwap, sourceSwap);
				//$('img[data-alt-src]')

				$('.showlogin').click( function ( e ) {
					e.preventDefault();
					$('.open_login_register').click();
				} );
				$('.openPublicar a, .openPublicar_2').click( function ( e ) {
					e.preventDefault();
					$('.open_login_register').click();
					$('.mfp-content [data-redirect="current"]').attr( 'data-redirect', $(this).attr('href') );
					setTimeout( function () {
						$('.mfp-content [data-redirect="current"]').attr( 'data-redirect', $(this).attr('href') );
					}, 500 );
				} );
				$('.open_login_register, .open_register').magnificPopup({
					'type': 'inline',
					'midClick': true,
					'fixedContentPos': true,
					'fixedBgPos': true,
				});
				$('a.go_top').click( function ( e ) {
					e.preventDefault();
					$('html, body').animate({
						scrollTop: 0
					}, 1500);
				});
				/*
				$('.navbar-toggler').click( function ( e ) {
					e.preventDefault();
					$('#navbarSupportedContent').toggleClass('openleft');
					
					if( jQuery('#navbarSupportedContent').hasClass('openleft') ) {
						$('body').css( {
							'overflow-y': 'hidden',
						} );
					} else {
						$('body').css( {
							'overflow-y': 'auto',
						} );
					}

				$('.navbar-toggler').magnificPopup({
					'type': 'inline',
					'midClick': true,
					'fixedContentPos': true,
					'fixedBgPos': true,
					callbacks: {
						resize: function() {
							if ( window.innerWidth >= 1260 ) {
								$.magnificPopup.close();
							}
						},
					}
				});
				*/
				$('a[href="#openSearch"]').click( function (e) {
					e.preventDefault();
					$(this).parent().find( '.search-form').fadeToggle();
					$(this).parent().find( '.search-form .search-field').focus();
					$(this).parent().find( '.search-form .search-field')[0].selectionStart = $(this).parent().find( '.search-form .search-field')[0].selectionEnd = $(this).parent().find( '.search-form .search-field').val().length;
				} );
				$('[name="user_login"]').change( function ( e ) {
					$('#reggister_user_name').val( $(this).val() );
					$('#reggister_user_email').val( $(this).val() );
					$('#reggister_log').val( $(this).val() );
				} );
				$('#open_login_register form').submit( function ( e ) {
					var $form = $(this);
					e.preventDefault();
					$form.find('.alert').remove();
					var data = {
						'action': 'loginWithEmail',
						'email': $form.find('[name="log"]').val(),
						'password': $form.find('[name="pwd"]').val(),
					};
					jQuery.post(ajax_object.ajax_url, data, function(response) {
						console.log( response );
						if ( response.result == 'success' ) {
							$form.prepend('<div class="alert alert-success" style="padding-top: 0.3em; padding-bottom: 0.1em; " role="alert">'+response.msg+'</div>');
							setTimeout( function ( ) {
								location.reload();
							}, 100 );
						}
						else {
							$form.prepend('<div class="alert alert-danger" style="padding-top: 0.3em; padding-bottom: 0.1em; " role="alert">'+response.msg+'</div>');
						}
					});
				} );
				var $window = $(window);
				var resize = false;
				var timer;
				$('.flexslider').on('resize',function(event){
					//event.stopPropagation();
				});
				var resize_loop = 0;
				$window.resize(function() {
					clearTimeout(timer);
					if ( resize_loop < 1 ) {
						timer = setTimeout( function(){
							var slider1 = $('.flexslider').data('flexslider');
							if( typeof slider1 != 'undefined' ) {
								slider1.resize();
							}
							resize_loop++;
						}, 500);
					} else {
						setTimeout( function() {
							resize_loop = 0;
						}, 500);
					}
				});
			},
			finalize: function() {
				jQuery( document ).ready( function ( $ ) {
					// JavaScript to be fired on all pages, after page specific JS is fired
					if ( ajax_object.list_ids && ajax_object.list_ids.length ) {
						$('.col-menu-central a').each( function ( ) {
							var $this_menu = $(this);
							var current_name = $( this ).text();
							ajax_object.list_ids.forEach( function ( item, index ) {
								if ( item.childs && item.childs.length ) {
									item.childs.forEach( function ( _item, _index ) {
										if ( _item.name == current_name ) {
	    									$this_menu.css( '--current-hover', item.color );
										}
									} );
								}
							} );
						} );
						$('#mega-menu-wrap-primary_navigation #mega-menu-primary_navigation.mega-menu-vertical > li.mega-menu-item a').each( function ( ) {
							var $this_menu = $(this);
							var current_name = $( this ).text();
							ajax_object.list_ids.forEach( function ( item, index ) {
								if ( item.name == current_name && item.childs && item.childs.length ) {
	    							$this_menu.css( '--current-hover', item.color );
								}
							} );
						} );

						$('#mega-menu-wrap-primary_navigation #mega-menu-primary_navigation.mega-menu-vertical > li.mega-menu-item').hover( function ( e ) {
							var $this_menu = $(this);
							var current_name = $( this ).find('> a').text();
							ajax_object.list_ids.forEach( function ( item, index ) {
								if ( item.name == current_name && item.childs && item.childs.length ) {
									item.childs.forEach( function ( _item, _index ) {
										$('.col-menu-central a').each( function ( ) {
											var $this_sub_menu = $(this);
											var sub_current_name = $( this ).text();
											if ( _item.name == sub_current_name ) {
												$this_sub_menu.addClass('hover');
											}
										} );
									} );
								}
							} );
						}, function ( e ) {
							var $this_menu = $(this);
							var current_name = $( this ).find('> a').text();
							ajax_object.list_ids.forEach( function ( item, index ) {
								if ( item.name == current_name && item.childs && item.childs.length ) {
									item.childs.forEach( function ( _item, _index ) {
										$('.col-menu-central a').each( function ( ) {
											var $this_sub_menu = $(this);
											var sub_current_name = $( this ).text();
											if ( _item.name == sub_current_name ) {
												$this_sub_menu.removeClass('hover');
											}
										} );
									} );
								}
							} );
						} );
						
					}
				} );
			}
		},
		// Home page
		'single': {
			finalize: function() {
				var _this = this;
				var $window = $(window);
				var sticky = new Sticky('body:not(.informes-especiales) .sidebar .widget');
			}
		},
		// Home page
		'single_post': {
			getGridSize: function( xs, sm, md, lg, xl, xxl ) {
				return ( window.innerWidth < 544 ) ? xs : ( window.innerWidth < 768 ) ? sm : ( window.innerWidth < 992 ) ? md : ( window.innerWidth < 1260 ) ? lg: ( window.innerWidth < 1440 ) ? xl: xxl;
			},
			initSliders: function ( ) {
				var _this = this;
				$('.releted_products.flexslider').flexslider({
					animation: "slide",
					itemWidth: 280,
					pauseOnAction: false,
					pauseOnHover: true,
					itemMargin: 0,
					minItems: _this.getGridSize(1, 2, 3, 3, 3, 3),
					maxItems: _this.getGridSize(1, 2, 3, 3, 3, 3),
					controlNav: false,
					animationLoop: true,
					prevText: "",
					nextText: "",
					//mousewheel: true,
					start: function(slider){
						$('body').removeClass('loading');
						_this.flexsliderProducts = slider;
					}
				});

				$('.thumbnails .slides > li').fadeOut(0);
				$(".thumbnails .slides > li[data-attribute_general='true']").fadeIn(0);
				$('.thumbnails.flexslider').flexslider({
					selector: ".slides > li:not([style*='display: none'])",
					animation: "slide",
					itemWidth: 230,
					pauseOnAction: false,
					pauseOnHover: true,
					itemMargin: 0,
					minItems: _this.getGridSize(2, 2, 3, 4, 4, 6),
					maxItems: _this.getGridSize(2, 2, 3, 4, 4, 6),
					controlNav: false,
					animationLoop: true,
					prevText: "",
					nextText: "",
					slideshow: false,
					//mousewheel: true,
					start: function(slider){
						$('body').removeClass('loading');
						_this.flexsliderThumbnails = slider;
						$('.variations select').change();

						/*
						var yith_wcmg_image = $('.yith_magnifier_zoom img');
						yith_wcmg_image.elevateZoom({
							gallery: isMobile.any?'woocommerce_product_thumbnails_mobile':'woocommerce_product_thumbnails_pc',
							cursor: 'pointer',
							galleryActiveClass: 'active',
							borderSize: 1,
							imageCrossfade: true,
							loadingIcon: ''
						});
						*/
					}
				});
			},
			flexsliderThumbnails: { vars:{} },
			flexsliderProducts: { vars:{} },
			destroySlider: function() {
				var _this = this;
				if ( isMobile.any ) {
					$('.releted_products.flexslider').removeData("flexslider");
					$('.thumbnails.flexslider').removeData("flexslider");
					_this.initSliders();
				}
				else {
					var gridSizeProducts = _this.getGridSize( 1, 2, 3, 3, 3, 3 );
					var gridSizeThumbnails = _this.getGridSize( 1, 2, 3, 3, 3, 3 );
					_this.flexsliderProducts.vars.minItems = gridSizeProducts;
					_this.flexsliderThumbnails.vars.maxItems = gridSizeThumbnails;
				}
			},
			init: function() {
				// JavaScript to be fired on the home pagevar 
				var _this = this;
				var $window = $(window);
				_this.initSliders();

				$window.resize(function() {
					if ( isMobile.any ) {
						_this.destroySlider();
					}
					else {
						var gridSizeProducts = _this.getGridSize( 1, 2, 3, 3, 3, 3 );
						var gridSizeThumbnails = _this.getGridSize( 1, 2, 3, 3, 3, 3 );
						_this.flexsliderProducts.vars.minItems = gridSizeProducts;
						_this.flexsliderThumbnails.vars.maxItems = gridSizeThumbnails;
					}
				});

				//$.fn.datepicker.defaults.format = "mm/dd/yyyy";
				var args = { 
					startDate:			'+1d',
					dateFormat:			'dd/mm/yy',
					altField:			'',
					altFormat:			'yymmdd',
					changeYear:			true,
					yearRange:			"-100:+100",
					changeMonth:		true,
					showButtonPanel:	true,
					minDate: 			0,
					firstDay:			1
				};
				$('.wapf-input').datepicker(args);
				if( $('body > #ui-datepicker-div').length ) {
					$('body > #ui-datepicker-div').wrap('<div class="acf-ui-datepicker" />');
				}

				jQuery('.product_featured_image a').magnificPopup({
				  type:'image',
				  callbacks: {
					elementParse: function(item) {
					  console.log(item); // Do whatever you want with "item" object
					}
				  }
				});
			},
			finalize: function() {
				var _this = this;
				// JavaScript to be fired on the home page, after the init JS
				setTimeout( function () {
					_this.destroySlider();
				}, 1000);
				setTimeout( function () {
					_this.destroySlider();
				}, 2000);

				var yith_wcmg_gallery = $('.woocommerce-product-gallery__wrapper .product_thumbnails .woocommerce-product-gallery__image a');
				var yith_wcmg_image = $('.woocommerce-product-gallery__wrapper .product_featured_image .woocommerce-product-gallery__image img');
				$('.woocommerce-product-gallery__wrapper .product_featured_image .woocommerce-product-gallery__image a').click( function ( e ) {
					e.preventDefault();
				} );
				yith_wcmg_gallery.click( function ( e ) {
					e.preventDefault();
					var _yith_wcmg_image = $(this).attr('href');
					yith_wcmg_image.attr('src', _yith_wcmg_image);
					yith_wcmg_image.data('src', _yith_wcmg_image);
					yith_wcmg_image.attr('srcset', _yith_wcmg_image);
					yith_wcmg_image.parent().attr('href', _yith_wcmg_image);
					/*
					var ez = yith_wcmg_image.data('elevateZoom');   
					ez.imageSrc = _yith_wcmg_zoom;
					ez.currentImage = _yith_wcmg_zoom;
					ez.zoomImage = _yith_wcmg_zoom;
					ez.refresh(0);
					*/
				} );
			}
		},
		// Home page
		'home': {
			readyVideo: function (player_id) {
				var froogaloop = $(player_id);
				froogaloop.addEvent('play', function(data) {
					$('.widget_products_slider_widget .flexslider').flexslider("pause");
				});

				froogaloop.addEvent('pause', function(data) {
					$('.widget_products_slider_widget .flexslider').flexslider("play");
				});
			},
			getGridSize: function( xs, sm, md, lg, xl, xxl ) {
				return ( window.innerWidth < 544 ) ? xs : ( window.innerWidth < 768 ) ? sm : ( window.innerWidth < 992 ) ? md : ( window.innerWidth < 1260 ) ? lg: ( window.innerWidth < 1440 ) ? xl: xxl;
			},
			initSliders: function ( ) {
				var _this = this;
				var total_width = 0;
				$('#list_socios_flexslider .slides > li').each(function() {
					total_width+=$(this).width();
				});
				var avg_width = total_width / $('#list_socios_flexslider .slides > li').length;
				$('#list_socios_flexslider').flexslider({
					animation: "slide",
					slideshowSpeed:5000,
					animationSpeed:350,
					//itemWidth: avg_width,
					itemWidth: isMobile.any ? 280 : ( window.innerWidth > 1360 ? ( window.innerWidth > 1440 ? 195 : 203 ) : 190),
					pauseOnAction: false,
					pauseOnHover: true,
					itemMargin: 0,
					minItems: 1,
					maxItems: 10,
					initDelay: 3000,
					controlNav: false,
					animationLoop: true,
					prevText: "",
					nextText: "",
				});

				if ( innerWidth > 767 ) {
					$('.widget_products_slider_widget .flexslider').flexslider({
						animation: "slide",
						//animationLoop: false,
						itemWidth: 320,
						pauseOnAction: false,
						pauseOnHover: true,
						itemMargin: 0,
						minItems: _this.getGridSize(1, 2, 3, 3, 3, 3),
						maxItems: _this.getGridSize(1, 2, 3, 3, 3, 3),
						controlNav: true,
						animationLoop: true,
						prevText: "",
						nextText: "",
						//mousewheel: true,
						start: function(slider){
							$('body').removeClass('loading');
							_this.flexsliderProducts = slider;
						}
					});
				} else {
					$('.widget_products_slider_widget .flexslider').addClass('pl-0 pr-0');
					$('.widget_products_slider_widget .flexslider ul').removeClass('slides');
					$('.widget_products_slider_widget .flexslider ul').addClass('pl-0 list-unstyled mb-0');
					$('.widget_products_slider_widget .section_wrap').addClass('text-center');
				}

				function addEvent(element, eventName, callback) {
					if (element.addEventListener) {
						element.addEventListener(eventName, callback, false);
					} else {
						element.attachEvent(eventName, callback, false);
					}
				}
				
				var vimeoPlayers = $('.widget_wc_videos_slider .flexslider').find('iframe'), player;
				for (var i = 0, length = vimeoPlayers.length; i < length; i++) {
					player = vimeoPlayers[i];
					//$(player).addEvent('ready', _this.readyVideo);
				}
				
				$('.widget_wc_videos_slider .flexslider').flexslider({
					animation: "slide",
					//animationLoop: false,
					itemWidth: 192,
					pauseOnAction: false,
					pauseOnHover: true,
					itemMargin: 0,
					minItems: _this.getGridSize(1, 2, 3, 2, 2, 2),
					maxItems: _this.getGridSize(1, 2, 3, 2, 2, 2),
					controlNav: false,
					animationLoop: true,
					prevText: "",
					nextText: "",
					//mousewheel: true,
					start: function(slider){
						$('body').removeClass('loading');
						_this.flexsliderVideos = slider;
					},
					before: function(slider){
						for (var i = 0, length = vimeoPlayers.length; i < length; i++) {
							//$(vimeoPlayers[i]).api('pause');
						}
					}
				});
				$('.widget_wc_posts_slider .flexslider').flexslider({
					animation: "slide",
					//animationLoop: false,
					itemWidth: 426,
					pauseOnAction: false,
					pauseOnHover: true,
					itemMargin: 0,
					//minItems: 1,
					//maxItems: 4,
					minItems: _this.getGridSize(1, 2, 2, 3, 3, 3),
					maxItems: _this.getGridSize(1, 2, 2, 3, 3, 3),
					controlNav: false,
					animationLoop: true,
					prevText: "",
					nextText: "",
					//mousewheel: true,
					start: function(slider){
						$('body').removeClass('loading');
						_this.flexsliderPosts = slider;
					}
				});
			},
			flexsliderPosts: { vars:{} },
			flexsliderProducts: { vars:{} },
			flexsliderVideos: { vars:{} },
			destroySlider: function() {
				var _this = this;
				$('.widget_products_slider_widget .flexslider').removeData("flexslider");
				$('.widget_wc_videos_slider .flexslider').removeData("flexslider");
				$('.widget_wc_posts_slider .flexslider').removeData("destroy");

				$('#list_socios_flexslider').removeData("flexslider");
				$('#list_socios_flexslider').removeData("destroy");
				_this.initSliders();
			},
			init: function() {
				// JavaScript to be fired on the home pagevar 
				var _this = this;
				var $window = $(window);

				_this.initSliders();
				$window.resize(function() {
					if ( isMobile.any ) {
						_this.destroySlider();
					}
					else {

						var gridSizePosts = _this.getGridSize( 1, 2, 2, 3, 3, 3 );
						_this.flexsliderPosts.vars.minItems = gridSizePosts;
						_this.flexsliderPosts.vars.maxItems = gridSizePosts;

						var gridSizeVideos = _this.getGridSize( 1, 2, 3, 2, 2, 2 );
						_this.flexsliderVideos.vars.minItems = gridSizeVideos;
						_this.flexsliderVideos.vars.maxItems = gridSizeVideos;

						var gridSizeProducts = _this.getGridSize( 1, 2, 3, 3, 3, 3 );
						_this.flexsliderProducts.vars.minItems = gridSizeProducts;
						_this.flexsliderProducts.vars.maxItems = gridSizeProducts;
					}
				});
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'finalizar_compra': {
			init: function() {
				// JavaScript to be fired on the about us page
				if ( $('#billing_invoice').val() == 'Factura' ) {
					$('.show_invoice').fadeIn();
				}
				else {
					$('.show_invoice').fadeOut();
					$('#billing_ruc').val('');
					$('#billing_company').val('');
					$('#billing_address_invoice').val('');
				}
				$('#billing_invoice').change( function ( ) {
					if ( $(this).val() == 'Factura' ) {
						$('.show_invoice').fadeIn();
					}
					else {
						$('.show_invoice').fadeOut();
						$('#billing_ruc').val('');
						$('#billing_company').val('');
						$('#billing_address_invoice').val('');
					}   
				} );




				if( $('#billing_district').val() == '(Yo recogeré mi pedido - SIN REPARTO)' ) {
					$('#billing_address_1').val('Calle Los Mogaburos 215. Departamento 208. Oficina 2. Jesús María. Lima-Perú.');
					$('#billing_reference').val('A una cuadra y veinte pasos del Hospital del Niño, cruzando la avenida Brasil, hacia el lado de Jesús María.');

					//$("#billing_address_1").prop('disabled', true);
					//$("#billing_reference").prop('disabled', true);

					$("#billing_address_1_field").removeClass('woocommerce-invalid');
					$("#billing_address_1_field label").html('Dirección para que recojas tu pedido');
					$("#billing_reference_field label").html('Referencia');
					$("#billing_address_1").after('<p style="font-size: 1rem; line-height: 1;">Calle Los Mogaburos 215. Departamento 208. Oficina 2. Jesús María. Lima-Perú.</p>');
					$("#billing_reference").after('<p style="font-size: 1rem; line-height: 1;">A una cuadra y veinte pasos del Hospital del Niño, cruzando la avenida Brasil, hacia el lado de Jesús María.</p>');
					$('#billing_address_1').fadeOut(0);
					$('#billing_reference').fadeOut(0);
				}
				else {
					$('#billing_address_1').val('');
					$('#billing_reference').val('');

					$("#billing_address_1").prop('disabled', false);
					$("#billing_reference").prop('disabled', false);
					$("#billing_address_1_field label").html('Dirección <abbr class="required" title="obligatorio">*</abbr>');
					$("#billing_reference_field label").html('Referencias (opcional)');
					$("#billing_address_1").next('p').remove();
					$("#billing_reference").next('p').remove();
					$('#billing_address_1').fadeIn(0);
					$('#billing_reference').fadeIn(0);
				}
				$('#billing_district').change( function ( ) {
					if ( $(this).val() == '(Yo recogeré mi pedido - SIN REPARTO)' ) {
						$('#billing_address_1').val('Calle Los Mogaburos 215. Departamento 208. Oficina 2. Jesús María. Lima-Perú.');
						$('#billing_reference').val('A una cuadra y veinte pasos del Hospital del Niño, cruzando la avenida Brasil, hacia el lado de Jesús María.');

						//$("#billing_address_1").prop('disabled', true);
						//$("#billing_reference").prop('disabled', true);

						$("#billing_address_1_field").removeClass('woocommerce-invalid');
						$("#billing_address_1_field label").html('Dirección para que recojas tu pedido');
						$("#billing_reference_field label").html('Referencia');
						$("#billing_address_1").after('<p style="font-size: 1rem; line-height: 1;">Calle Los Mogaburos 215. Departamento 208. Oficina 2. Jesús María. Lima-Perú.</p>');
						$("#billing_reference").after('<p style="font-size: 1rem; line-height: 1;">A una cuadra y veinte pasos del Hospital del Niño, cruzando la avenida Brasil, hacia el lado de Jesús María.</p>');
						$('#billing_address_1').fadeOut(0);
						$('#billing_reference').fadeOut(0);
					}
					else {
						$('#billing_address_1').val('');
						$('#billing_reference').val('');

						$("#billing_address_1").prop('disabled', false);
						$("#billing_reference").prop('disabled', false);
						$("#billing_address_1_field label").html('Dirección <abbr class="required" title="obligatorio">*</abbr>');
						$("#billing_reference_field label").html('Referencias (opcional)');
						$("#billing_address_1").next('p').remove();
						$("#billing_reference").next('p').remove();
						$('#billing_address_1').fadeIn(0);
						$('#billing_reference').fadeIn(0);
					}
				});
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
